<template>
    <div class="modal-form" style="min-width:1000px;max-width: 95vw!important;">
      <h3 class="card-title">Añadir empleado a notificar</h3>
      <table-list
        :showFilter="true"
        :data="personas"
        :header="tablelist.headers"
        :body="tablelist.body"
        @rowClicked="empleadoSelected"
        ></table-list>
    </div>
</template>
<script>
import TableList from "@/components/elements/TableList";
export default {
    name: 'AddEmpleadoParaNotificar',
    props: ['personas', 'tablelist'],
    emits: ['addEmpleado'],
    components: {
        TableList
    },
    methods: {
        empleadoSelected(empleado){
            this.$emit('addEmpleado', empleado)
        }
    }
}
</script>